<template>
  <v-dialog
    v-bind="$attrs"
    class="mem-dialog"
    overlay-color="#000000"
    overlay-opacity="0.54"
    fullscreen
  >
    <div class="share-result-dialog" :key="renderKey">
      <div class="overlay-controls">
        <span class="close-btn material-icons" @click="$emit('close')">
          close
        </span>
      </div>
      <div class="overlay-body">
        <div class="overlay-title">{{ $t("share_your_result") }}</div>

        <div class="overlay-subtitle">
          {{ $t("share_modal_subtitle") }}
        </div>
        <template v-if="showSlider">
          <splide
            ref="splide"
            :options="splideOptions"
            @splide:active="onSlideChange"
          >
            <!-- stories -->
            <splide-slide>
              <div class="stories-slide">
                <img
                  v-if="fileStories.src"
                  :src="fileStories.src"
                  class="stories-image"
                  alt="slide-stories"
                />
                <div v-else class="stories-skeleton"></div>
              </div>
            </splide-slide>
            <!-- feed -->
            <splide-slide>
              <div class="feed-slide">
                <img
                  v-if="fileFeed.src"
                  :src="fileFeed.src"
                  class="feed-image"
                  alt="slide-feed"
                />
                <div v-else class="feed-skeleton"></div>
              </div>
            </splide-slide>
          </splide>
        </template>

        <div class="slider-skeleton" v-if="!showSlider"></div>

        <div class="slider-pagination">
          <div
            class="pagination-item"
            :class="{ 'is-active': activeSlide === 'stories' }"
          ></div>
          <div
            class="pagination-item"
            :class="{ 'is-active': activeSlide === 'feed' }"
          ></div>
        </div>

        <div class="overlay-buttons">
          <!-- stories buttons -->
          <template v-if="activeSlide === 'stories'">
            <div
              class="file-download result-download-file-stories"
              :class="{ 'is-disabled': !fileStories.file }"
              @click="shareFile('downloadStories')"
            >
              <i class="material-icons" v-if="!inProgress.downloadStories">
                download
              </i>
              <v-progress-circular
                v-if="inProgress.downloadStories"
                indeterminate
                color="#000000"
                size="25"
                width="2"
              ></v-progress-circular>
            </div>
            <mem-button
              class="result-share-stories-btn"
              :btn-type="'secondary-dark'"
              :loading="inProgress.shareStories"
              :disabled="!fileStories.file"
              @click="shareFile('shareStories')"
            >
              {{ $t("share_modal_btn_stories") }}
            </mem-button>
          </template>

          <!-- feed buttons -->
          <template v-else>
            <div
              class="file-download result-download-file-feed"
              :class="{ 'is-disabled': !fileFeed.file }"
              @click="shareFile('downloadFeed')"
            >
              <i class="material-icons" v-if="!inProgress.downloadFeed">
                download
              </i>
              <v-progress-circular
                v-if="inProgress.downloadFeed"
                indeterminate
                color="#000000"
                size="25"
                width="2"
              ></v-progress-circular>
            </div>
            <mem-button
              class="result-share-feed-btn"
              :btn-type="'secondary-dark'"
              :loading="inProgress.shareFeed"
              :disabled="!fileFeed.file"
              @click="shareFile('shareFeed')"
            >
              {{ $t("share_modal_btn_feed") }}
            </mem-button>
          </template>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
export default {
  name: "ShareResultDialog",
  inheritAttrs: false,
  components: {
    Splide,
    SplideSlide,
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  data: () => ({
    splideOptions: {
      arrows: false,
      pagination: false,
      perPage: 3,
      perMove: 1,
      focus: "center",
      trimSpace: false,
      autoWidth: true,
      gap: 50,
      height: 320,
      // width: 375,
    },

    activeSlide: "",
    activeSlideIndex: 0,
    showSlider: false,

    fileStories: {},
    fileFeed: {},

    inProgress: {
      downloadStories: false,
      downloadFeed: false,
      shareStories: false,
      shareFeed: false,
    },
  }),
  props: {
    resultId: [Number, String],
    renderKey: {
      type: Number,
      default: 0,
    },
  },
  computed: {},
  watch: {
    renderKey: {
      handler() {
        this.showSlider = false;
        setTimeout(() => {
          this.showSlider = true;
        }, 300);
      },
    },
  },
  methods: {
    ...mapActions(["getResultShareImage"]),
    onSlideChange() {
      let { splide } = this.$refs;
      this.activeSlideIndex = splide.index;

      if (splide.index === 0) this.activeSlide = "stories";
      if (splide.index === 1) this.activeSlide = "feed";
    },
    async shareFile(shareType) {
      if (this.inProgress[shareType]) return;

      this.inProgress[shareType] = true;
      try {
        let image;
        if (this.activeSlide === "stories") image = this.fileStories;
        if (this.activeSlide === "feed") image = this.fileFeed;

        if (!image.file) return;

        this.inProgress[shareType] = false;

        let res = await navigator.share({ files: [image.file] });
        console.log(res, "Share result");

        // GTM Event
        if (this.activeSlide === "stories")
          this.$emit("share", "resultShareStories");
        if (this.activeSlide === "feed") this.$emit("share", "resultShareFeed");

        this.$emit("close");
      } catch (error) {
        console.log(error);
        // this.$emit("close");
      }
    },
    async getImage(link) {
      try {
        let res = await fetch(link);
        let blob = await res.blob();
        return {
          file: new File([blob], `result_${this.resultId}.png`, {
            type: blob.type,
          }),
          src: URL.createObjectURL(blob),
        };
      } catch (error) {
        console.log(`Can't get image file from ${link}`, error);
      }
    },
  },
  async mounted() {
    try {
      // stories
      this.fileStories = await this.getResultShareImage({
        resultId: this.resultId,
        orientation: "portrait",
      });
      // feed
      this.fileFeed = await this.getResultShareImage({
        resultId: this.resultId,
        orientation: "landscape",
      });
    } catch (error) {
      console.log(`Can't get image file. Result ID: ${this.resultId}`, error);
    }

    // resultShareVisible
  },
};
</script>
<style lang="scss" scoped>
.share-result-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  background-color: #121212;
  height: 100%;
  .overlay-controls {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .close-btn {
      color: #717171;
      padding-left: 20px;
      padding-right: 20px;
      @include cursorPointer;
    }
  }
  .overlay-body {
    max-width: 375px;
    width: 100%;

    .overlay-title {
      font-family: "Druk Wide";
      font-size: 18px;
      font-weight: 950;
      line-height: 16px;
      text-transform: uppercase;

      text-align: center;
      margin-top: 22px;
      margin-bottom: 22px;
    }
    .overlay-subtitle {
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
      letter-spacing: 0.0025em;
      padding: 0 50px;
      margin-bottom: 38px;
      text-align: center;
    }

    .stories-slide {
      height: 320px;
    }
    .feed-slide {
      height: 320px;
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
    }

    .stories-image {
      height: 320px;
      width: 180px;
    }
    .feed-image {
      height: 150px;
      width: 285px;
    }

    .stories-skeleton {
      animation: skeleton-loading 0.5s linear infinite alternate;
      height: 320px;
      width: 180px;
    }
    .feed-skeleton {
      animation: skeleton-loading 0.5s linear infinite alternate;
      height: 150px;
      width: 285px;
    }

    .slider-skeleton {
      height: 320px;
    }

    .slider-pagination {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      padding-top: 18px;
      padding-bottom: 4px;
      .pagination-item {
        height: 6px;
        width: 6px;
        border-radius: 50%;
        margin: 0 4px;
        background-color: #383838;
      }
      .is-active {
        background-color: #ffffff;
      }
    }
    .overlay-buttons {
      display: grid;
      grid-template-columns: max-content 1fr;
      padding: 30px 40px;
      .file-download {
        height: 44px;
        width: 44px;
        color: #000000;
        background-color: #ffffff;
        border-radius: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        @include cursorPointer;
      }
      .is-disabled {
        background-color: #717171;
      }
    }
  }
}
</style>